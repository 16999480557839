import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Formación con Drupal, presencial y a distancia | asdloop" description="Impartimos cursos de formación Drupal, desarrollando el programa formativo a medida de tus necesidades, tanto presencial como a distancia, en inglés y castellano." />
  <section className="jumbotron bg-transparent pb-6" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">Formación Drupal</h1>
        <p className="lead mb-4 col-md-9 offset-md-2 col-sm-12">Ofrecemos formación presencial y a distancia a distintos perfiles técnicos Drupal, para cualquier nivel de expertise.</p>
      </div>
    </div>
  </section>
  <section className=" pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Tipos de formaciones Drupal</strong>
          </h2>
          <h3 className="pt-4">Formación a medida</h3>
          <p className="lead">Desarrollamos un programa formativo a medida de tus necesidades, cuéntanos que quieres aprender y te propondremos el programa y desarrollaremos los contenidos y ejercicios prácticos del curso.</p>
          <h3 className="pt-2">Formación genérica sobre una versión de Drupal</h3>
          <p className="lead">Tenemos cursos desarrollados por nosotros con genéricos de Drupal 7 y 8 que adaptaremos a tu disponibilidad de horas formativas.</p>
          <h3 className="pt-2">Formación in-company y a distancia</h3>
          <p className="lead">Impartimos los cursos tanto a distancia como presencialmente, en inglés y en castellano.</p>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ProjectHelse
